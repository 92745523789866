<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <b-button variant="link" class="text-primary refresh" v-b-tooltip.hover.bottom :title="$t('general.refresh')" @click="getClinics">
                <i class="fas fa-sync-alt"></i>
            </b-button>
            <base-button type="success" @click="addNew" class="my--5">
                <i class="fas fa-plus"></i> {{$t('general.addnew')}}</base-button>
        </b-card>
        <b-card no-body v-if="clinics" class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="clinics" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')" :row-class-name="tableRowClassName">
                <el-table-column :label="$t('general.name')" prop="name" min-width="160">
                </el-table-column>

                <el-table-column :label="$t('general.branch')" prop="branch" min-width="160">
                    <template v-slot="{row}">
                        <small v-html="$options.filters.showBranch(row.branch)"></small>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('general.country')" prop="country" min-width="160">
                    <template v-slot="{row}">
                        {{row.country | showCountry}}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('general.city')" prop="city" min-width="160">
                    <template v-slot="{row}">
                        {{row.city | showCity}}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('general.status')" prop="status" min-width="140">
                    <template v-slot="{row}">
                        <badge class="badge-dot mr-4" type="">
                            <i :class="`bg-${status[row.status]}`"></i>
                            <span class="status" :class="`text-${status[row.status]}`">{{row.status?$t('general.active'):$t('general.passive')}}</span>
                        </badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.created_date')" prop="createdAt" min-width="170" sortable>
                    <template v-slot="{row}">
                        {{row.createdAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="180">
                    <template slot-scope="scope">
                        <base-button type="primary" size="sm" @click="handleEdit(scope.row)">{{$t('general.edit')}}</base-button>
                        <base-button type="danger" size="sm" @click="handleDelete(scope.row.uuid)">{{$t('general.delete')}}</base-button>
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>

        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modalClose" size="lg">
            <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                <h6 slot="header" class="modal-title">{{modal.data ? $t('clinic.edit') : $t('clinic.add')}}</h6>
                <hr>
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)" v-loading="modal.loading">
                    <b-row>
                        <b-col md="4">
                            <label class="form-control-label">{{$t('general.status')}}</label>
                            <b-form-checkbox v-model="modal.data.status" class="switchCheck dblock" name="check-button" switch>
                                {{modal.data.status ? $t('general.active') : $t('general.passive')}}
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="4">
                            <base-input type="text" name="name" :nameAs="$t('clinic.name')" :label="$t('clinic.name')" :placeholder="$t('clinic.name')" @input="generateSeo('name')" v-model="modal.data.name" required>
                            </base-input>
                        </b-col>
                        <b-col md="4">
                            <base-input type="text" name="seo" :nameAs="$t('clinic.seo')" :label="$t('clinic.seo')" :placeholder="$t('clinic.seo')" @input="generateSeo('seo')" v-model="modal.data.seo" required>
                            </base-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="4">
                            <base-input type="text" name="url" :nameAs="$t('clinic.url')" :label="$t('clinic.url')" :placeholder="$t('clinic.url')" v-model="modal.data.url">
                            </base-input>
                        </b-col>
                        <b-col md="4">
                            <base-input type="text" name="phone" :nameAs="$t('general.phone')" :label="$t('general.phone')" :placeholder="$t('general.phone')" v-model="modal.data.phone">
                            </base-input>
                        </b-col>
                        <b-col md="4">
                            <base-input type="email" name="email" :nameAs="$t('general.email')" :label="$t('general.email')" :placeholder="$t('general.email')" v-model="modal.data.email">
                            </base-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="4">
                            <base-input :label="$t('general.country')" name="country" :nameAs="$t('general.country')" :rules="'required'">
                                <el-select v-model="modal.data.country" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required>
                                    <el-option v-for="(item, key) in countries" :key="key" :label="item.name" :value="item._id"></el-option>
                                </el-select>
                            </base-input>
                        </b-col>
                        <b-col md="4">
                            <base-input :label="$t('general.city')" name="city" :nameAs="$t('general.city')">
                                <el-select
                                    v-model="modal.data.city"
                                    filterable
                                    :no-match-text="$t('warning.no_match')"
                                    :no-data-text="$t('warning.no_data')"
                                    :placeholder="$t('general.select')"
                                    @change="changeCity"
                                >
                                    <el-option v-for="(item, key) in cities" :key="key" :label="item.name" :value="item._id"></el-option>
                                </el-select>
                            </base-input>
                        </b-col>
                        <b-col md="4">
                            <base-input type="text" name="address" :nameAs="$t('general.address')" :label="$t('general.address')" :placeholder="$t('general.address')" v-model="modal.data.address">
                            </base-input>
                        </b-col>
                    </b-row>
                    <div class="simple-map">
                        <GmapMap :center.sync="center" :options="mapOptions" map-type-id="terrain" @click="markerDrag">
                            <GmapMarker :position="modal.data.location" :clickable="true" :draggable="true" @dragend="markerDrag" v-if="modal.data.location.lat>0" />
                        </GmapMap>
                    </div>
                    <hr>
                    <b-alert show variant="warning" v-if="invalid">
                        <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                    </b-alert>
                    <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid">{{$t('save')}}</base-button>
                    <base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false" :disabled="modal.loading">{{$t('close')}}</base-button>
                </b-form>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import getSlug from 'speakingurl';
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { CLINIC_REQUEST, CLINIC_UPDATE, CLINIC_ADD, CLINIC_DELETE } from '@/store/modules/define';
import { handleError } from '@/utils/func';
import { setTimeout } from 'timers';

let self;
let emptyModal = {
    status: true,
    name: '',
    seo: '',
    url: '',
    email: '',
    phone: '',
    address: '',
    location: { lat: 0, lng: 0 },
    country: '6007eee0aaf082368c83d163',
    city: ''
};
export default {
    computed: {
        ...mapGetters({ clinics: 'getClinics', total: 'getClinicTotal', pages: 'getClinicPages', branchList: 'getBranchList', countries: 'getCountry', cities: 'getCity' })
    },
    data() {
        const mapOptions = this.$root.defaultMapOptions;
        return {
            center: mapOptions.center,
            mapOptions: mapOptions,
            modal: {
                loading: false,
                status: false,
                data: _.cloneDeep(emptyModal)
            },
            loading: true,
            status: {
                true: 'success',
                false: 'danger'
            },
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {}
        };
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            if(!row.status){
                return 'table-danger';
            }
            return '';
        },
        generateSeo(key) {
            let lang = self.$i18n.locale;
            self.modal.data.seo = getSlug(self.modal.data[key], { lang });
        },
        changeCity(value) {
            let city = _.get(
                _.filter(self.cities, item => {
                    return item._id == value;
                }),
                0
            );

            let marker = { lat: parseFloat(city.latitude), lng: parseFloat(city.longitude) };
            _.set(self.modal.data, 'location', marker);
            self.center = _.cloneDeep(marker);
        },
        markerDrag(location) {
            let marker = { lat: location.latLng.lat(), lng: location.latLng.lng() };
            _.set(self.modal.data, 'location', marker);
            self.center = _.cloneDeep(marker);
        },
        changePage(val) {
            self.loading = true;
            self.pagination.page = val;
            self.getClinics();
        },
        handleEdit(row) {
            self.modal.loading = false;
            self.modal.status = true;
            self.modal.data = _.cloneDeep(emptyModal);
            self.modal.data = _.merge(self.modal.data, row);
            if (self.modal.data.location.lat != 0) {
                setTimeout(() => {
                    self.center = _.cloneDeep(self.modal.data.location);
                }, 500);
            }
        },
        handleDelete(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(CLINIC_DELETE, uuid)
                        .then(resp => {
                            self.loading = false;
                            self.getClinics();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        modalClose() {
            self.modal.loading = false;
            self.modal.status = false;
            self.modal.data = _.cloneDeep(emptyModal);
        },
        addNew() {
            self.modal.loading = false;
            self.modal.status = true;
            self.modal.data = _.cloneDeep(emptyModal);
        },
        onSubmit() {
            /*
            if (self.modal.data.city != '' && self.modal.data.location.lat == 0) {
                self.$notify({ type: 'warning', message: self.$i18n.t('warning.no_location') });

                return false;
            }

            if (self.modal.data.city == '') {
                self.$notify({ type: 'warning', message: self.$i18n.t('warning.no_city') });

                return false;
            }
            */

            if (self.modal.data.uuid != undefined) {
                self.onUpdate();
            } else {
                self.onAdd();
            }
        },
        onAdd() {
            let clinic = _.pickBy(self.modal.data, (item, key) => {
                switch (typeof item) {
                    case 'boolean':
                        return true;
                        break;
                    case 'string':
                        return item.trim() != '';
                        break;
                    default:
                        return true;
                        break;
                }
            });

            self.modal.loading = true;
            self.$store
                .dispatch(CLINIC_ADD, clinic)
                .then(resp => {
                    self.modalClose();
                    self.getClinics();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        onUpdate() {
            let uuid = self.modal.data.uuid;
            let clinic = _.pickBy(self.modal.data, (item, key) => {
                if (key == 'uuid') {
                    return false;
                }

                switch (typeof item) {
                    case 'boolean':
                        return true;
                        break;
                    case 'string':
                        return item.trim() != '';
                        break;
                    default:
                        return true;
                        break;
                }
            });

            self.modal.loading = true;
            self.$store
                .dispatch(CLINIC_UPDATE, { uuid, clinic })
                .then(resp => {
                    self.modal.loading = false;
                    self.getClinics();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        getClinics() {
            self.loading = true;
            self.$store
                .dispatch(CLINIC_REQUEST, { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        }
    },
    filters: {
        showCountry(value) {
            let country = _.get(
                _.filter(self.countries, item => {
                    return item._id == value;
                }),
                0
            );

            return country ? country.name : '';
        },
        showCity(value) {
            let city = _.get(
                _.filter(self.cities, item => {
                    return item._id == value;
                }),
                0
            );

            return city ? city.name : '';
        },
        showBranch(value) {
            let lang = self.$i18n.locale;
            let branches = _.map(
                _.pickBy(self.branchList, item => {
                    return item.status && _.includes(value, item._id);
                }),
                item => {
                    return item.name[lang];
                }
            );

            return _.join(branches, '<br/> ');
        }
    },
    created() {
        self = this;
        self.getClinics();
    }
};
</script>
